'use client';

import { usePathname, useRouter } from 'next/navigation';
import { Locale, i18n, useLanguage } from '@repo/i18n';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@ui/components/ui/select';
import { cn } from '@ui/lib/utils';

export const LanguageSwitcher = ({
  lang,
  className,
}: {
  lang: Locale;
  setLang: (lang: Locale) => void;
  className?: string;
}) => {
  const { dictionary } = useLanguage();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _targetLanguage, ...path] = usePathname().split('/');

  const router = useRouter();

  console.log('concat', path.join('/'));

  const flagMap: { [key in Locale]: string } = {
    en: '🇺🇸',
    es: '🇪🇸',
  };

  return (
    <Select
      defaultValue={lang}
      onValueChange={(e) => router.replace(`/${e}/${path.join('/')}`)}
    >
      <SelectTrigger
        className={cn('md:max-w-40 max-w-32 px-2 mx-0', className)}
      >
        <SelectValue placeholder='Language' className='' />
      </SelectTrigger>
      <SelectContent className='w-full'>
        {i18n.locales.map((locale) => (
          <SelectItem
            className='flex flex-row space-y-3'
            key={locale}
            value={locale}
          >
            {flagMap[locale]} {dictionary?.languages[locale]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
